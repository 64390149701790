const ruUk = {

	'нужна вода и еда': 'потрібні вода та їжа',
	'требуется эвакуация': 'потрібна евакуація',
	'медицина, требуются лекарства': 'медицина, потрібні ліки',
	актуально: 'актуально',
	'была эвакуация, нет актуальных данных': 'була евакуація, немає актуальних даних',
	'нет данных об эвакуации': 'немає даних про евакуацію',
	'пока остаются, запроса нет': 'вирішили залишитися, запиту немає',
	'частично в списках эвакуированных': 'частково в списках евакуюваних',
	вывезли: 'вивезли',
	погибли: 'загиблі',
	животные: 'тварини',
	'нет контакта': null,
	
	// disregard
	дубль: null,
	приплюсовали: null,
	'пустая строка': null,
	
	Эвакуированы: 'Евакуйовані',
	Пропавшие: 'Зниклі',
	'Общий поиск': 'Пошук усіх',
	
	Фильтры: 'Фільтри',
	Выделить: 'Виділити',
	сбросить: 'скинути',
	Список: 'Список',
	
	'показать только': 'показати лише',
	'показать также': 'показати також',
	статус: null,
	фильтры: 'фільтри',
	выгрузка: null,
	'люди с животными': null,
	
	'Ничего не выбрано': 'Нічого не вибрано',
	'Не нашлось': 'Не знайдено',
	'Нет координат': 'Немає координат',
	
	точек: 'точок',
	Людей: 'Людей',
	Животных: 'Тварин',
	Адрес: 'Адреса',
	Координаты: 'Координати',
	Телефон: 'Телефон',
	'Контактная информация': 'Контактна інформація',
	Детали: 'Подробиці',
	
	Пользователь: null,
	Пароль: null,
	Войти: null,
	'есть доступ': null,
	'нет доступа': null,
	'Неверный логин или пароль': null,
	
	Визиком: 'Візіком',
	
} as const;

export type TranslationId = keyof typeof ruUk;

export const languageConfig: {
	language: 'ru' | 'uk';
} = { language: 'ru' }; // for now

export function t(id: TranslationId) {
	return languageConfig.language == 'uk' ? ruUk[id] ?? id : id;
}
